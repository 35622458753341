function activateVideo(object, single = false) {
  if (single) {
    var videourl = object.data('url') + '&autoplay=1';
    object.parent().siblings('iframe').attr('src', videourl);
  } else {
    object.each(function() {
      var videourl = jQuery(this).data('url');
      jQuery(this).parent().siblings('iframe').attr('src', videourl);
    });
  }
  object.parent().parent().css('margin-bottom', 0);
	object.parent().siblings('.gallery-item__overlay-image').hide();
  object.parent().hide();
}

function checkCookie() {
  if (hasCookieSplitVal('ww-cookies', 'youtube')) {
    activateVideo(jQuery('.play-btn--js--youtube'));
  }
	if (hasCookieSplitVal('ww-cookies', 'vimeo')) {
    activateVideo(jQuery('.play-btn--js--vimeo'));
  }
}

jQuery('.video-privacy__checkbox-youtube').click(function() {
  if (jQuery(this).is(':checked')) {
		activateVideo(jQuery('.play-btn--js--youtube'));
    addCookieSplitVal('ww-cookies', 'youtube', 30);
  }
});

jQuery('.play-btn--js--youtube').click(function(e) {
  e.preventDefault();
  activateVideo(jQuery(this), true);
});

jQuery('.video-privacy__checkbox-vimeo').click(function() {
  if (jQuery(this).is(':checked')) {
		activateVideo(jQuery('.play-btn--js--vimeo'));
    addCookieSplitVal('ww-cookies', 'vimeo', 30);
  }
});

jQuery('.play-btn--js--vimeo').click(function(e) {
  e.preventDefault();
  activateVideo(jQuery(this), true);
});

jQuery(document).ready(function() {
  checkCookie();
});
